var tour;
$( document ).ready(function() {
    var tour_guide_btn = $('#tour-guide-btn');
    if (tour_guide_btn.length) {
        (function() {
        // more generic input typeword func
        // @param obj
        // @param key (=string value to type)
        // @param append (=boolean if true, just append new word)
        var typeWord = function(obj, key, append, callback) {
            var $this = $(obj).focus();
                      
            for (var i=0, len=key.length; i<len; i++) {
                if (i == 0) {
                    if (!append) {
                        $this.val('');
                        if ($this.hasClass('typeahead')) {
                            $this.typeahead('val','');
                        }
                    }
                }
                (function() {
                    var temp_i = i; 
                    setTimeout(function() {
                        if (key.substr(temp_i,1) == "\n") {
                            $this.val($this.val() + String.fromCharCode(13));
                            $this.blur();
                            $this.focus();

                        } else {
                            if ($this.hasClass('typeahead')) {
                                $this.typeahead('val', $this.typeahead('val') + key.substr(temp_i,1));
                            } else {
                                $this.val($this.val() + key.substr(temp_i,1));
                            }
                        }

                        if (callback && temp_i == len-1) {
                            callback();
                        }
                    }, temp_i*170);
                })()
            }
        };

        // get translations
        $.ajax({
            url: tour_guide_btn.attr('data-url'),
            success: function(data) {
                if ('trans' in data) {
                    for (var r in data.trans) {
                        if (typeof data.trans[r] == 'object' && 'text' in data.trans[r]) {
                            data.trans[r].text = nl2br(data.trans[r].text);
                        }
                    }
                    setupTour(tour_guide_btn.attr('data-tour'), data.trans);
                } else {
                    tour_guide_btn.hide()
                }
            },
            error: function() {
                tour_guide_btn.hide()
            }
        });

        // set step in popover
        var currentStep = 0;
        var setCurrentStep = function(tour) {
            var currentStep = tour.getCurrentStep() + 1;
            var totalSteps = tour._options.steps.length;
            $('.tour.popover #counter').text(currentStep + " / " + totalSteps);
        };

        // add new step
        var addStep = function(config) {
            if (!config) {
                config = {};
            }
            if (config.mobile_disable && tour.isMobile()) {
                return;
            }

            if (!config.element) {
                config.orphan = true;
            }
            if (!config.placement) {
                config.placement = 'auto';
            }
            if (!('hide_counter' in config)) {
                config.hide_counter = true;
            }
            if (config.content) {
                config.content = nl2br(config.content);
            }
            if (config.prev_end) {
                $('body').on('click', '.tour.popover [data-role=prev]', function() {
                    tour.end();  
                });
            }
            if (config.next_end) {
                $('body').on('click', '.tour.popover [data-role=next]', function() {
                    tour.end();  
                });
            }


            // add setStep for every step
            var onShown = function(tour) {
                $('body').addClass('tour-carrie-visible')
                setCurrentStep(tour);

                if (config.hide_counter) {
                    $('.tour.popover #counter').text('');
                }
                if (config.prev_hide) {
                    $('.tour.popover [data-role=prev]').hide();
                }
                if (config.next_hide) {
                    $('.tour.popover [data-role=next]').hide();
                }
                if (config.prev_hide && config.next_hide) {
                    $('.tour.popover').addClass('no-navigation');
                }
                if (config.prev_end || config.prev_enable || config.prev_do || config.onPrev) {
                    $('.tour.popover [data-role=prev]').removeClass('disabled').removeAttr('disabled');
                }
                if (config.next_end || config.next_enable || config.next_do || config.onNext) {
                    $('.tour.popover [data-role=next]').removeClass('disabled').removeAttr('disabled');
                }
                if (config.prev_text) {
                    $('.tour.popover [data-role=prev]').text(config.prev_text);
                }
                if (config.next_text) {
                    $('.tour.popover [data-role=next]').text(config.next_text);
                }
                if (config.prev_do) {
                    $('.tour.popover [data-role=prev]').click(function() {
                        config.prev_do(tour);
                    });
                }
                if (config.next_do) {
                    $('.tour.popover [data-role=next]').click(function(e) {
                        config.next_do(e, tour);
                    });
                }
                if (!config.title) {
                    $('.tour.popover').addClass('step-without-title');
                    $('.tour.popover .popover-title').show();
                }
            };
            if (!config.onShown) {
                config.onShown = onShown;
            } else {
                var onShown2 = config.onShown;
                config.onShown = function(tour) {
                    onShown(tour);
                    onShown2(tour);
                }
            }
            if (config.onHide) {
                var onHide = config.onHide;
                config.onHide = function(tour) {
                    $('body').removeClass('tour-carrie-visible');
                    onHide(tour);
                };
            }

            // fix for sometimes not working "next" event
            if (!config.onNext) {
                config.onNext = function(tour) {
                    var curr_step = tour.getCurrentStep();
                    setTimeout(function() {
                        if (curr_step == tour.getCurrentStep()) {
                            tour.goTo(parseInt(curr_step) + 1);
                        }
                    }, 300)
                };
            }

            tour.addStep(config);
        };


        // tour
        var setupTour = function(type, obgt) {
            var $template = "<div class='popover tour'> <div class='carrot'><img src='/img/carrot.png' /></div>  <div class='carrot-arrow'><img src='/img/arrow-onboard.png' /></div> <h3 class='popover-title'></h3> <div class='popover-content'></div> <div class='row popover-navigation'> <div class='col-xs-5'><button class='btn btn-default pull-left' data-role='prev'>&lt; "+obgt.button.prev+"</button></div> <div class='col-xs-2 counter'><span data-role='counter' id='counter'></span></div> <div class='col-xs-5'><button class='btn btn-default pull-right' data-role='next'>"+obgt.button.next+" &gt;</button></div> </div> <a href='#' data-role='end'>x</a><div class='row popover-external invisible-std'><div class='col-xs-12'><button class='btn btn-default pull-left' id='tour-left-btn'></button><button class='btn btn-default pull-right' id='tour-right-btn'></button></div></div></div>";


            /****************************************************************************
            * OnBoard Guide INSTANCE "tour"
            *****************************************************************************/
            var tour_name = "carrie-tour-"+type;
            tour = new Tour({
                name: tour_name, 
                keyboard: true,
                template: $template,
                backdrop: true,
                //storage: window.localStorage,
                storage: false, 
                backdropContainer: 'body',
                backdropPadding: false,
                backdropPadding: 15,
                smartPlacement: true,
                onShown: function (tour) {
                    setCurrentStep(tour);
                    $('body').addClass(tour_name).addClass('tour-carrie-visible')
                },
                onStart: function(tour) {
                    if (!tour_guide_btn.hasClass('visited')) {
                        var url = tour_guide_btn.attr('data-url-on-start');
                        if (url) {
                            $.get(url);
                        }
                    };
                    $('body').addClass(tour_name)
                },
                onResume: function(tour) {
                    $('body').addClass(tour_name)
                },
                onPause: function(tour) {
                    $('body').removeClass(tour_name).removeClass('tour-carrie-visible')
                },
                onEnd: function(tour) {
                    $('body').removeClass(tour_name).removeClass('tour-carrie-visible')
                },
                onHide: function(tour) {
                    $('body').removeClass(tour_name).removeClass('tour-carrie-visible');
                },
                steps: []
            });

            // tour init
            tour.isVisible = function() {
                return $('body').hasClass('tour-carrie-visible');
            };
            tour.getTotalSteps = function() {
                return tour._options.steps.length;
            }
            tour.isMobile = function() {
                return $('body').hasClass('carrie-tour-on-smartphone');
            }

            // tour start on init
            tour_guide_btn.click(function(){
                tour.init();
                tour.restart();

                var step = $(this).attr('data-step');
                if (step && step >= 0) {
                    tour.goTo(step); 
                }
            });

            $('body').addClass(tour_name);

            var tourPrepare = function() {
                if ($('.smartphone-menu').is(':visible')) {
                    $('body').addClass('carrie-tour-on-smartphone');
                } else {
                    $('body').removeClass('carrie-tour-on-smartphone');
                }
            };
            tourPrepare();

            // redraw tour does not work here for some reason
            // restart step is pretty solid solution
            var refreshTour;
            $(window).resize(function() {
                clearTimeout(refreshTour);
                refreshTour = setTimeout(function() {
                    tourPrepare();
                    tour.goTo(tour.getCurrentStep())
                }, 100);
            });

            /****************************************************************************
            * OnBoard Guide STEPS
            *****************************************************************************/
            // WELCOME && DASHBOARD
            if (type == 'dashboard') {
                var hi_user_text = obgt.start.title;
                if (USER && USER.salutation.length) {
                    hi_user_text = hi_user_text
                        .replace('{salutation}', USER.salutation)
                        .replace('{lname}', USER.lname);
                } else {
                    hi_user_text = hi_user_text.replace(/%[A-Za-z0-9]+/gi, '');
                }

                hi_user_text = $.trim(hi_user_text.replace(/\s+/, '  '));

                addStep({
                    title: hi_user_text, 
                    content: obgt.start.text,
                    prev_end: true,
                    prev_text: obgt.start.finish,
                    next_text: obgt.start.continue,
                });
                addStep({
                    element: $('#middle-content-block .module-wrapper'),
                    placement: 'top',
                    content: obgt.modules.text,
                    prev_hide: true,
                    next_hide: true,
                    onShown: function(tour) {
                        $('a.module-option').on('click', function(e) {
                            e.preventDefault();
                            var a = $(this), href = a.attr('href');
                            document.location.href = href + '?carrie=1&step=0';
                            return false;
                        })
                    },
                    onHide: function(tour) { 
                        $('a.module-option').off('click');
                    },
                    onNext: function(tour) {
                        tour.end(); 
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep({
                    element: $('#middle-content-block .module-wrapper'),
                    placement: 'top',
                    title: obgt.end.title,
                    content: obgt.end.text,
                    prev_hide: true,
                    next_hide: true,
                    onShown: function(tour) {
                        $('a.module-option').on('click', function(e) {
                            e.preventDefault();
                            var a = $(this), href = a.attr('href');
                            document.location.href = href + '?carrie=1';
                            return false;
                        });
                        tour_guide_btn.attr('data-step', 0);
                    },
                    onHide: function(tour) { 
                        $('a.module-option').off('click');
                    },
                    onPrev: function(tour) {
                        tour.end(); 
                        return (new jQuery.Deferred()).promise();
                    },
                    onNext: function(tour) {
                        tour.end(); 
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep();

            } else
            // RECIPES
            if (type == 'recipes') {
                addStep({
                    element: $('#btn-create-recipe'),
                    placement: 'bottom',
                    title: obgt.new_recipe.title,
                    content: obgt.new_recipe.text,
                    prev_hide: true,
                    next_do: function(e, tour) {
                        $('#btn-create-recipe').click();
                    },
                    onShown: function(tour) {
                        $('.main-content-menu').addClass('tour-focused');
                        $('.tour.popover, .tour-step-background').each(function() {
                            var $this = $(this).get(0);
                            $this.style.removeProperty('position');
                            $this.style.setProperty('position', 'fixed', 'important');
                        });
                        var a = $('#btn-create-recipe');
                        a.data('href', a.attr('href'));
                        a.on('click', function(e) {
                            e.preventDefault();
                            var a = $(this), href = a.attr('href');
                            document.location.href = href + '?carrie=1&step=0';
                            return false;
                        });
                    },
                    onHide: function(tour) {
                        $('.main-content-menu').removeClass('tour-focused');
                    },
                    onEnd: function(tour) {
                        var a = $('#btn-create-recipe');
                        a.attr('href', a.data('href')).off('click');
                    },
                    onNext: function(tour) {
                        $('#btn-create-recipe').click();
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep();

            } else
            // RECIPE
            if (type == 'recipe') {
                var new_recipe = $('.recipe-form.recipe-new').length;
                if (!new_recipe) {
                        addStep({
                            title: obgt.pre_start.title,
                            content: obgt.pre_start.text,
                            prev_hide: false,
                            next_hide: false,
                            prev_text: obgt.pre_start.no,
                            next_text: obgt.pre_start.yes,
                            onPrev: function(tour) {
                                tour.goTo(1);
                            },
                            onNext: function(tour) {
                                window.open('/recipes/new?carrie=1&step=0', '_blank');
                                return (new jQuery.Deferred()).promise();
                            },
                            onShown: function() {
                                console.log(tour._options.steps);
                            }
                        });
                }
                addStep({
                    element: ".main-data",
                    title: obgt.recipename.title,
                    content: obgt.recipename.text,
                    prev_hide: true,
                    onShown: function(tour) {
                        var el = $(".recipe-text .tab-pane:visible input").val('');
                        clearTimeout(el.data('typeWord'));
                        setTimeout(function() {
                            typeWord(el, obgt.recipename.name || '');
                        }, 500);
                    },
                    onNext: function(tour) {
                        tour.goTo(1)
                    }
                });
                addStep({
                    element: ".recipe-categories",
                    title: obgt.categories.title,
                    content: obgt.categories.text,
                    onShown: function(tour) {
                        var el = $(".recipe-categories input");
                        clearTimeout(el.data('typeWord'));
                        el.data('typeWord', setTimeout(function() {
                            $('.recipe-categories .token').remove();
                            typeWord(el, (obgt.categories.categories || ''), false, function() { 
                                $('#recipe-description').focus();
                            });
                        }, 500));
                    }
                });
                addStep({
                    element: "#recipe-price-wrapper",
                    title: obgt.price.title,
                    content: obgt.price.text,
                    onShown: function(tour) {
                        var el = $('#recipe-price').val('');
                        clearTimeout(el.data('typeWord'));
                        el.data('typeWord', setTimeout(function() {
                            typeWord(el, '5,99');
                        }, 500));
                    },
                });
                addStep({
                    element: ".ingredients-wrapper",
                    title: obgt.ingredientslist.title,
                    content: obgt.ingredientslist.text
                });
                if ($(".ingredient-name-div:last").length) {
                    (function() {
                        var ing, sug;

                        addStep({
                            element: '.recipe-form .ing-row:not(.ingredient-selected):last',
                            title: obgt.addingnewingredient.title,
                            content: obgt.addingnewingredient.text,
                            onShow: function(tour){
                                ing = $('.recipe-form .ing-row:not(.ingredient-selected):last');
                                ing.find(' > .form-group > div').css('z-index', 9999);
                                ing.find('.ingredient-name.typeahead.tt-input').val('');

                                ing.find('.ingredient-quantity-amount').on('focus', function() {
                                    //tour.goTo(tour.getCurrentStep()+1);
                                    //$(this).off('focus'); 
                                })

                                // auto select first value in the autocomplete list
                                ing.find('.typeahead').on('typeahead:opened', function(ev, a, b) {
                                    setTimeout(function() {
                                        sug = ing.find('.tt-suggestion').first().addClass('tt-cursor');
                                        setTimeout(function() {
                                            sug = ing.find('.tt-suggestion.tt-cursor');
                                            if (!sug.length) {
                                                sug = ing.find('.tt-suggestion').first();
                                            }
                                            sug.click();
                                            $(".recipe-form .ing-row:last").removeClass(function (index, className) {
                                                return (className.match (/(^|\s)tour-\S+/g) || []).join(' ');
                                            }); 

                                            // set quantity
                                            setTimeout(function() {
                                                $(".recipe-form .ing-row.ingredient-selected:last .ingredient-quantity-amount").val(6).trigger('keydown');
                                                $(".recipe-form .ing-row.ingredient-selected:last .ingredient-quantity-unit-values [data-value=piece]").click();
                                                //typeWord($(".recipe-form .ing-row.ingredient-selected:last .ingredient-quantity-amount").val(''), "100");
                                            }, 1500);
                                        }, 1000)
                                    }, 2000)
                                });

                                // next button
                                $("button[data-role='next']").click(function() {
                                    sug = ing.find('.tt-suggestion.tt-cursor');
                                    if (!sug.length) {
                                        sug = ing.find('.tt-suggestion').first();
                                    }
                                    sug.click();
                                });
                            },
                            onShown: function(tour) {
                                var key = obgt.key;

                                // ingredient search 
                                setTimeout(function() {
                                    //$('.recipe-form .ing-row:not(.ingredient-selected) .ingredient-name.typeahead.tt-input').each(function() {
                                    ing.find('.ingredient-name.typeahead.tt-input').each(function() {
                                        $(this).typeahead('val','')
                                        typeWord(this, key);
                                    });  
                                }, 500);
                            },
                            onHide: function(tour) {
                                $('.recipe-form .ing-row > .form-group > div').css('z-index', '');
                            },
                            onNext: function() {
                                ing.addClass('tour-was-here');
                                sug = ing.find('.tt-suggestion.tt-cursor');
                                if (!sug.length) {
                                    sug = ing.find('.tt-suggestion').first();
                                }
                                sug.click();
                            }
                        });
                    })();
                }
                addStep({
                    element: "#portioning",
                    title: obgt.portion.title,
                    content: obgt.portion.text,
                    onShown: function(tour) {
                        setTimeout(function() {
                            typeWord($("[name=portion_size]"), "135");

                            setTimeout(function() {
                                typeWord($("[name=persons]"), "3");
                            }, 1000);
                        }, 1000);
                    }
                });
                addStep({
                    element: ".cmcalc-master-wrap",
                    title: obgt.cmcalc.title,
                    content: obgt.cmcalc.text,
                });
                addStep({
                    element: ".recipe-comments",
                    title: obgt.comment.title,
                    content: obgt.comment.text,
                    onNext: function(tour) {
                        window.scrollTo(0, 0);
                    }
                });

                addStep({
                    element: $("#btn-recipe-save"), 
                    title: obgt.save.title,
                    content: obgt.save.text,
                    position: 'bottom left',
                    onShown: function(tour) {
                        $('.main-content-menu').addClass('tour-focused');
                        $('.tour.popover, .tour-step-background').each(function() {
                            var $this = $(this).get(0);
                            $this.style.removeProperty('position');
                            $this.style.setProperty('position', 'fixed', 'important');
                        });
                        $('.recipe-form').append('<input type="hidden" name="carrie" value="1">');
                    },
                    onHide: function(tour) {
                        $('.main-content-menu').removeClass('tour-focused');

                        setTimeout(function() {
                            $('.recipe-form [name=carrie]').remove();
                        }, 3000)
                    },
                    onNext: function(tour) {
                        $('#btn-recipe-save').click();
                        return (new jQuery.Deferred()).promise();
                    },
                    onEnd: function(tour) {
                        $('.recipe-form [name=carrie]').remove();
                    }
                });
                addStep({
                    element: $("#btn-recipe-save"),
                })
            } else
            // RECIPE VIEW
            if (type == 'recipeview') {
                addStep({
                    element: "#right-menu",
                    title: obgt.nutritions.title,
                    content: obgt.nutritions.text,
                    prev_hide: true,
                    placement: 'left',
                    onShow: function(tour) {
                        openRightSidebar(); 
                    },
                    onShown: function(tour) {
                        $("#right-menu").css('z-index', 9999);
                        var $this = $('.tour-step-background').get(0);
                        $this.style.removeProperty('position');
                        $this.style.setProperty('position', 'fixed', 'important');
                    },
                    onHide: function(tour) {
                        $("#right-menu").css('z-index', '');
                    }
                });
                addStep({
                    element: (tour.isMobile() ? ".main-content-menu .menu-toggle" : ".main-content-menu ul"),
                    title: obgt.navi.title,
                    content: obgt.navi.text,
                    placement: 'bottom',
                    onShow: function(tour) {
                        closeRightSidebar(); 
                    },
                    onShown: function(tour) {
                        $('.main-content-menu').addClass('tour-focused');
                        $('.tour.popover, .tour-step-background').each(function() {
                            var $this = $(this).get(0);
                            $this.style.removeProperty('position');
                            $this.style.setProperty('position', 'fixed', 'important');
                        });
                        tour.redraw();
                    },
                    onHide: function(tour) {
                        $('.main-content-menu').removeClass('tour-focused');
                    },
                    onNext: function(tour) {
                        document.location.href = '/dashboard?carrie=1&step=2'
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep();
            } else 
            // SHOPS 
            if (type == 'shops') {
                addStep({
                    title: obgt.index.title,
                    content: obgt.index.text,
                    prev_hide: true,
                    next_text: obgt.index.connect,
                    next_do: function(tour) {
                        document.location.href = '/shops/manage?carrie=1&step=0'
                    },
                    onNext: function(tour) {
                        document.location.href = '/shops/manage?carrie=1&step=0'
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep();
            } else 
            // SHOPS MANAGE
            if (type == 'shopsmanage') {
                addStep({
                    element: '.list-shops',
                    title: obgt.manage.title,
                    content: obgt.manage.text,
                    placement: 'screen-bottom',
                    prev_hide: true,
                    onShown: function(tour) {
                        if (tour.isMobile()) {
                            $('.tour-step-background, .tour-backdrop').hide();
                            $('.tour-step-backdrop').css('z-index', 'auto');
                        }
                        $('body').on('shown.bs.modal', '.modal.shop-info-modal', function(e) {
                            if (!tour.ended()) {
                                tour.goTo(1);
                            }
                        });
                    },
                    onNext: function(tour) {
                        $('body').off('click', '.list-shops .single.shop-public a');

                        // find first public not connected shop
                        $('.list-shops .single.shop-public:not(.shop-status-connected):not(.shop-status-waiting)').each(function() {
                            $(this).find('a').click();
                            return false;
                        })
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep({
                    element: '.shop-info-modal .user-shop-status',
                    title: obgt.connect.title,
                    content: obgt.connect.text,
                    onHide: function(tour) {
                        $('.modal.shop-info-modal').modal('hide');
                        $('body').off('shown.bs.modal', '.modal.shop-info-modal');
                    },
                    onShown: function(tour) {
                        $('.popover.tour').css('z-index', 1151);
                    },
                });

                addStep({
                    element: '#shops-link',
                    container: (tour.isMobile() ? 'body' :  '#left-menu'),
                    title: obgt.shops_link.title,
                    content: obgt.shops_link.text,
                    placement: 'right reversed',
                    //mobile_disable: true,
                    onShow: function(tour) {
                        openLeftSidebar(); 
                    },
                    onShown: function(tour) {
                        if (tour.isMobile()) {
                            $('.tour-step-background').css('z-index', 1);
                            $('#left-menu').css('z-index', 9999);
                        } else {
                            $('#left-menu').css({
                                'z-index': 'auto',
                               'position': 'absolute',
                            });
                        }

                        $('#shops-link').css({
                            'z-index': 9999,
                            'background': '#fff',
                        }).find('a').css('color', '#333')
                        $('.tour-step-background').css('background-color', '#3d4352');
                    },
                    onHide: function(tour) {
                        closeLeftSidebar();

                        $('#left-menu').css({
                            'z-index': '',
                            'position': '',
                        });
                        $('#shops-link').css({
                            'z-index': '',
                            'background': '',
                        }).find('a').css('color', '')
                        $('#left-menu').css('z-index', '');
                    },
                });
                addStep({
                    element: '#orders-link',
                    container: '#left-menu',
                    title: obgt.orders_link.title,
                    content: obgt.orders_link.text,
                    placement: 'right reversed',
                    //mobile_disable: true,
                    onShow: function(tour) {
                        openLeftSidebar(); 
                    },
                    onShown: function(tour) {
                        if (tour.isMobile()) {
                            $('.tour-step-background').css('z-index', 1);
                            $('#left-menu').css('z-index', 9999);
                        } else {
                            $('#left-menu').css({
                                'z-index': 'auto',
                               'position': 'absolute',
                            });
                        }

                        $('#orders-link').css({
                            'z-index': 9999,
                            'background': '#fff',
                        }).find('a').css('color', '#333')
                        $('.tour-step-background').css('background-color', '#3d4352');
                    },
                    onHide: function(tour) {
                        closeLeftSidebar();

                        $('#left-menu').css({
                            'z-index': '',
                            'position': '',
                        });
                        $('#shops-link').css({
                            'z-index': '',
                            'background': '',
                        }).find('a').css('color', '')
                        $('#left-menu').css('z-index', '');
                    }
                });
                addStep({
                    element: '#right-menu .infobox',
                    title: obgt.privateshop.title,
                    content: obgt.privateshop.text,
                    placement: 'left',
                    onShow: function(tour) {
                        openRightSidebar(); 
                    },
                    onShown: function(tour) {
                        $('.tour-step-background').css('background-color', '#3d4352');

                        if (tour.isMobile()) {
                            $('#right-menu').css({
                                'z-index': 9999
                            });
                        } else {
                            $('#right-menu').css({
                                'z-index': 'auto',
                                'position': 'absolute',
                            });
                        }
                    },
                    onHide: function(tour) {
                        closeRightSidebar(); 
                        $("#right-menu").css('z-index', '');
                    },
                    onNext: function(tour) {
                        document.location.href = '/dashboard?carrie=1&step=2'
                        return (new jQuery.Deferred()).promise();
                    }
                });
                addStep();
            } else
            // MENU
            if (type == 'menu') {
                tour.addStep({
                    orphan: true,
                    title: obgt.start.title,
                    content: obgt.start.text,
                    placement: 'auto',
                    onShown: function(tour) {
                        $('#counter').text('');
                        $("button[data-role=prev]").text(obgt.start.button_recipe)
                            .prop('disabled', false)
                            .removeClass('disabled')
                            .click(function() {
                                document.location.href = '/recipes/new?carrie=1';
                            });
                        $("button[data-role=next]").text(obgt.start.button_menu)
                            .prop('disabled', false)
                            .removeClass('disabled');
                    }
                });
                tour.addStep({
                    element: ".recipe-menu-conf-selector",
                    title: obgt.menu_conf.title,
                    content: obgt.menu_conf.text,
                    placement: 'auto',
                    onShown: function(tour) {
                        setCurrentStep(tour);
                    }
                });
                tour.addStep({
                    element: ".btn-add-group",
                    title: obgt.groups.title,
                    content: obgt.groups.text,
                    placement: 'auto',
                    onShown: function(tour) {
                        setCurrentStep(tour);
                        $('body').on('shown.bs.modal', '.modal.menu-add-group-modal', function() {
                            $(this).find('.input-text').val(obgt.groups.default);
                        });
                        $('xbody').on('hidden.bs.modal', '.modal.menu-add-group-modal', function() {
                            if ($(this).find('.input-text').val().length) {
                                tour.next()
                            }
                        })
                    },
                    onHidex: function() {
                        $('body').off('shown.bs.modal', '.modal.menu-add-group-modal');
                        $('body').off('hidden.bs.modal', '.modal.menu-add-group-modal');
                    }
                });
                tour.addStep({
                    element: ".btn-add-recipe",
                    title: obgt.recipes.title,
                    content: obgt.recipes.text,
                    placement: 'auto',
                    onShown: function(tour) {
                        setCurrentStep(tour);
                    },
                });
                tour.addStep({
                    orphan: true,
                    title: obgt.save.title,
                    content: obgt.save.text,
                    placement: 'auto'
                });
                var recipes_menu_btn = $('.top-navbar a.outline.dropdown-toggle.active');
                tour.addStep({
                    element: $('.navbar.navbar-inverse.navbar-fixed-top .navbar-nav:not(.top-menu)'), 
                    title: obgt.weekly.title,
                    content: obgt.weekly.text,
                    placement: 'auto',
                    onShown: function(tour){
                        $("button[data-role='prev']").text(obgt.button.end).attr('data-role','end');
                        $("button[data-role='next']").remove();
                        $('#counter').text('');
                    },
                });
            }

            // auto start
            if (tour_guide_btn.attr('data-auto-start') == 'true') {
                tour_guide_btn.click();
            }
        };
        })();
    } 
});
